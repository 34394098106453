import Main from './Main'

const Homepage = ({setError, setErrMsg}) => {
  return (
    <div>
      <div className='divsoverlay'>
      <Main setError={setError} setErrMsg={setErrMsg} />
    </div>
    </div>
  );
}

export default Homepage
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CONFIG from "./../abi/config.json";
import NFTABI from "../abi/nft.json";
import multicallABI from "../abi/multicall.json";
import { ethers } from "ethers";

const RPC_ENDPOINT = "https://mainnet.fusionnetwork.io";

const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_ENDPOINT);

const getContract = (abi, address, signer) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

const getMulticallContract = (chainId, signer) => {
  return getContract(multicallABI, CONFIG.MULTICALL_ADDRESS, signer);
};

const multicall = async (abi, calls) => {
  try {
    const itf = new ethers.utils.Interface(abi);
    const multi = getMulticallContract();
    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);

    const { returnData } = await multi.aggregate(calldata);
    const res = returnData.map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

const useFetchNFT = (provider, account, fetchNFTs, setFetchNFTs) => {
  const [nft, setNFT] = useState(null);
  const fetchWalletNFTs = async (account) => {
    try {
      const signer = provider.getSigner();
      const nftContract = new ethers.Contract(
        CONFIG.NFT_CONTRACT,
        NFTABI,
        signer
      );
      let totalSupply = await nftContract.totalSupply();
      totalSupply = Number(totalSupply);
      let calls = [];
      for (let i = 0; i < totalSupply; i++) {
        calls.push({
          name: "ownerOf",
          address: CONFIG.NFT_CONTRACT,
          params: [i],
        });
      }
      const result = await multicall(NFTABI, calls);
      let nft = { ownedNfts: [] };
      for (let i = 0; i < result.length; i++) {
        if (result[i][0].toLowerCase() !== account.toLowerCase()) continue;
        const id = i;
        nft.ownedNfts.push({
          id: {
            tokenId: id.toString(16),
          },
          media: [
            {
              gateway: `https://bunn.mypinata.cloud/ipfs/QmU4NaXZEwzMbkjaxCcwKGAqTa6C48BKRbAFhYEkYGBDa4`,
            },
          ],
          title: `Bunny #${id}`,
        });
      }
      setNFT(nft);
      setFetchNFTs(false);
    } catch (e) {
      console.log(e);
      setFetchNFTs(false);
    }
  };

  useEffect(() => {
    if (fetchNFTs) {
      fetchWalletNFTs(account);
    }
  }, [account, fetchNFTs]);

  return nft;
};

export default useFetchNFT;
